import React from 'react';
import { graphql } from 'gatsby';

import StaticPage from '../components/StaticPage';

export const query = graphql`
  query Page($uid: String!) {
    prismic {
      website_page(uid: $uid, lang: "en-gb") {
        title
        page_description
        body1 {
          ... on PRISMIC_Website_pageBody1Hero {
            type
            primary {
              hero_title
              hero_sub_title
              hero_text
              hero_button_text
              hero_button_url
              hero_image
              hero_small
              hero_background_color
            }
          }
          ... on PRISMIC_Website_pageBody1Hero_with_sign_up {
            type
            primary {
              hero_signup_title
              hero_signup_sub_title
              hero_signup_text
              hero_signup_cta_title
              hero_signup_cta_text
              hero_signup_button_text
              hero_signup_button_url
              hero_signup_small
            }
          }
          ... on PRISMIC_Website_pageBody1Sign_up_banner {
            type
            primary {
              sign_up_banner_title
              sign_up_banner_text
              sign_up_banner_button_text
              sign_up_banner_button_url
            }
          }
          ... on PRISMIC_Website_pageBody1Content_grid {
            type
            primary {
              content_grid_small
            }
            fields {
              content_grid_title
              content_grid_text
              content_grid_button_text
              content_grid_button_url
              content_grid_icon {
                _linkType
                ... on PRISMIC__ImageLink {
                  url
                }
              }
            }
          }
          ... on PRISMIC_Website_pageBody1Feature_image {
            type
            primary {
              feature_image_title
              feature_image_image
              feature_image_small
              feature_image_background_color
            }
          }
          ... on PRISMIC_Website_pageBody1Split_content {
            type
            primary {
              split_content_title
              split_content_sub_title
              split_content_text
              split_content_button_text
              split_content_button_url
              split_content_reverse
              split_content_image_width
              split_content_image
              split_content_fit_image
              split_content_small
              split_content_background_color
            }
          }
          ... on PRISMIC_Website_pageBody1Sign_up_cta {
            type
            primary {
              sign_up_cta_title
              sign_up_cta_text
              sign_up_cta_signup_title
              sign_up_cta_signup_text
              sign_up_cta_signup_button_text
              sign_up_cta_signup_button_url
              sign_up_cta_icon {
                _linkType
                ... on PRISMIC__ImageLink {
                  url
                }
              }
            }
          }
          ... on PRISMIC_Website_pageBody1Contact_us_banner {
            type
            primary {
              contact_us_title
              contact_us_sub_title
              contact_us_button_text
              contact_us_button_url
              contact_us_facebook_url
              contact_us_linkedin_url
              contact_us_instagram_url
            }
          }
          ... on PRISMIC_Website_pageBody1Title_banner {
            type
            primary {
              title_banner_title
              title_banner_image
              title_banner_text
            }
          }
          ... on PRISMIC_Website_pageBody1Text_content {
            type
            primary {
              rich_text_content_title
              rich_text_content_text
              rich_text_content_button_text
              rich_text_content_button_url
              rich_text_content_small
              rich_text_content_background_color
              rich_text_content_title_icon {
                _linkType
                ... on PRISMIC__ImageLink {
                  url
                }
              }
            }
          }
          ... on PRISMIC_Website_pageBody1Feature_list {
            type
            fields {
              feature_list_title
              feature_list_text
            }
            primary {
              feature_list_small
              feature_list_background_color
            }
          }
          ... on PRISMIC_Website_pageBody1Feature_list {
            type
            fields {
              feature_list_title
              feature_list_text
              feature_list_icon {
                _linkType
                ... on PRISMIC__ImageLink {
                  url
                }
              }
            }
            primary {
              feature_list_title
              feature_list_small
              feature_list_background_color
            }
          }
          ... on PRISMIC_Website_pageBody1Testimonials {
            type
            fields {
              testimonial_text
              testimonial_citation
            }
            primary {
              testimonial_title
              testimonial_small
              testimonial_background_color
            }
          }
          ... on PRISMIC_Website_pageBody1Case_study {
            type
            fields {
              case_study_slide_title
              case_study_slide_text
            }
            primary {
              case_study_title
              case_study_small
              case_study_background_color
              case_study_image
            }
          }
          ... on PRISMIC_Website_pageBody1Faqs {
            type
            fields {
              faq_item_title
              faq_item_text
            }
            primary {
              faq_title
              faq_small
              faq_background_color
            }
          }
          ... on PRISMIC_Website_pageBody1Logo_grid {
            type
            fields {
              logo_grid_image
              logo_grid_link_url
            }
            primary {
              logo_grid_title
              logo_grid_small
              logo_grid_background_color
            }
          }
          ... on PRISMIC_Website_pageBody1Button {
            type
            primary {
              button_link_text
              button_link_url
              button_link_center
              button_link_small
              button_link_background_color
            }
          }
          ... on PRISMIC_Website_pageBody1Testimonials_videos {
            type
            primary {
              video_button_text

              video_show_button
              video_button_center
              video_small
              video_background_color
              video_title
              video_text
            }
            fields {
              video_embed
            }
          }
          ... on PRISMIC_Website_pageBody1Video {
            type
            primary {
              video_button_text
              video_embed
              video_show_button
              video_button_center
              video_small
              video_background_color
              video_title
              video_text
            }
          }
        }
      }
    }
  }
`;

export default function Page({ data }) {
  const cmsData = (data && data.prismic && data.prismic.website_page) || {};

  return <StaticPage {...cmsData} />;
}
